<template>
  <div class="linear-feet-calc">
    <template v-if="!hideContainers">
      <p class="linear-feet-calc__containers-title">Linear Feet per container:</p>
      <ul class="linear-feet-calc__containers-list">
        <li
          class="linear-feet-calc__container-detail"
          v-for="(container, index) in linearFeetCalc.container"
          :key="index"
        >
          <span class="linear-feet-calc__container-item">Container {{ index + 1 }}:</span>
          <span>{{ container.linear_feet }}</span>
        </li>
      </ul>
    </template>
    <div class="linear-feet-calc__total">
      <span>Total Linear Feet:</span
      ><span class="info-linear-feet">{{ linearFeetCalc?.total_linear_feet }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "LinearFeetCalculation",
  props: {
    loading: Boolean,
    linearFeetCalc: Object,
  },
  data() {
    return {
      hideContainers: false,
    };
  },
  watch: {
    linearFeetCalc: {
      handler() {
        this.hideContainers = this.getContainersQuantity();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getContainersQuantity() {
      if (this.linearFeetCalc.container.length === 0) {
        return true;
      }
      const isLinearFeetNull = this.linearFeetCalc.container.some(
        (container) => parseInt(container.linear_feet, 10) === 0
      );
      return isLinearFeetNull;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-loading-spinner .circular {
  height: 30px;
  width: 30px;
  margin-top: 5px;
}

.linear-feet-calc {
  font-size: 13px;
  padding: 10px 10px;
  &__containers-title {
    text-align: left;
    font-weight: bold;
  }
  &__containers-list {
    padding-left: 20px;
    margin: 5px 0px 10px 0px;
  }
  &__container-item {
    font-weight: bold;
    margin-right: 2px;
  }
  &__container-detail {
    padding: 5px 0px;
    text-align: left;
  }
  &__total {
    background-color: $background-secondary;
    color: $color-white;
    height: 50px;
    display: flex;
    font-weight: bold;
    span {
      align-self: center;
      margin-left: 10px;
    }
  }
}
</style>
