<template>
  <div class="lineItem">
    <div
      class="lineItem-container"
      :id="'line-item' + index"
      v-for="(option, index) in selectedLineItems"
      :key="index"
    >
      <div class="lineItem-inputs">
        <div class="lineItem-inputs__form">
          <label for="hu_count" class="form-label">Hu Count</label>
          <el-input-number
            class="form-input"
            :class="emptyFields[index]['hu_count'] === true ? 'missing-field' : ''"
            name="hu_count"
            v-model="option.hu_count"
            controls-position="right"
            size="mini"
            :min="0"
            :disabled="noContainerSelected"
          >
            >
          </el-input-number>
        </div>
        <div class="lineItem-inputs__form">
          <div class="form-label">
            <label class="form-label__label" for="length">length(In)</label>
            <el-tooltip
              v-if="limitValueEntered[index]['lenght'] === true"
              placement="top"
              effect="light"
            >
              <div slot="content">
                The length entered exceeds the<br />
                limits of the selected container.
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <el-input-number
            class="form-input"
            :class="emptyFields[index]['lenght'] === true ? 'missing-field' : ''"
            name="lenght"
            v-model="option.lenght"
            controls-position="right"
            size="mini"
            :min="0"
            :disabled="noContainerSelected"
            @change="inputValidationHandler(index, 'lenght', $event)"
          >
          </el-input-number>
        </div>
        <div class="lineItem-inputs__form">
          <div class="form-label">
            <label class="form-label__label" for="width">Width(In)</label>
            <el-tooltip
              v-if="limitValueEntered[index]['width'] === true"
              placement="top"
              effect="light"
            >
              <div slot="content">
                The width entered exceeds the<br />
                limits of the selected container.
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <el-input-number
            class="form-input"
            :class="emptyFields[index]['width'] === true ? 'missing-field' : ''"
            name="width"
            v-model="option.width"
            controls-position="right"
            size="mini"
            :min="0"
            :disabled="noContainerSelected"
            @change="inputValidationHandler(index, 'width', $event)"
          >
          </el-input-number>
        </div>
        <div class="lineItem-inputs__form">
          <div class="form-label">
            <label class="form-label__label" for="height">Height(In)</label>
            <el-tooltip
              v-if="limitValueEntered[index]['height'] === true"
              placement="top"
              effect="light"
            >
              <div slot="content">
                The height entered exceeds the<br />
                limits of the selected container.
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <el-input-number
            class="form-input"
            :class="emptyFields[index]['height'] === true ? 'missing-field' : ''"
            name="height"
            v-model="option.height"
            controls-position="right"
            size="mini"
            :min="0"
            :disabled="noContainerSelected"
            @change="inputValidationHandler(index, 'height', $event)"
          >
          </el-input-number>
        </div>
        <div class="lineItem-inputs__form">
          <div class="form-label">
            <label class="form-label__label" for="weight">Weight(Lb)</label>
            <el-tooltip
              v-if="limitValueEntered[index]['weight'] === true"
              placement="top"
              effect="light"
            >
              <div slot="content">
                The weight entered exceeds the<br />
                limits of the selected container.
              </div>
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
          <el-input-number
            class="form-input"
            :class="emptyFields[index]['weight'] === true ? 'missing-field' : ''"
            name="weight"
            v-model="option.weight"
            controls-position="right"
            size="mini"
            :min="0"
            :disabled="noContainerSelected"
            @change="inputValidationHandler(index, 'weight', $event)"
          >
          </el-input-number>
        </div>
        <button
          class="delete-btn"
          @click="deleteLineItem(index)"
          v-if="selectedLineItems.length != 1"
        >
          <i class="el-icon-circle-close"></i>
        </button>
      </div>
    </div>
    <button class="add-btn" @click="addLineItem">Add line<i class="el-icon-plus"></i></button>
  </div>
</template>

<script>
import ValidateEmptyFields from "../../../utils/validateEmpyFields";

export default {
  name: "LineItems",
  props: {
    containerSelected: Object,
  },
  data() {
    return {
      noContainerSelected: true,
      selectedLineItems: [
        {
          hu_count: null,
          lenght: null,
          width: null,
          height: null,
          weight: null,
        },
      ],
      emptyFields: [
        {
          container_name: false,
          hu_count: false,
          lenght: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
      limitValueEntered: [
        {
          lenght: false,
          width: false,
          height: false,
          weight: false,
        },
      ],
    };
  },
  watch: {
    selectedLineItems: {
      deep: true,
      handler() {
        this.$emit("input", this.selectedLineItems);
      },
    },
    containerSelected() {
      if (Object.keys(this.containerSelected).length !== 0) {
        this.noContainerSelected = false;
      }
    },
    emptyFields: {
      deep: true,
      immediate: true,
      handler() {
        this.$store.commit("linearFeet/setEmptyFieldsLineItems", this.emptyFields);
      },
    },
  },
  methods: {
    inputValidationHandler(index, item, value) {
      if (item === "weight") {
        this.validateWeightPerUnit(index, value);
        return;
      }
      if (value > this.containerSelected[item]) {
        this.limitValueEntered[index][item] = true;
        this.emptyFields[index][item] = true;
      } else {
        this.limitValueEntered[index][item] = false;
        this.emptyFields[index][item] = false;
      }
    },
    validateWeightPerUnit(index, value) {
      /* eslint-disable */
      const weightPerUnit =
        this.selectedLineItems[index].hu_count > 0
          ? value / this.selectedLineItems[index].hu_count
          : value;
      if (weightPerUnit > this.containerSelected.weight) {
        this.limitValueEntered[index].weight = true;
        this.emptyFields[index].weight = true;
        return;
      }
      this.limitValueEntered[index].weight = false;
      this.emptyFields[index].weight = false;
    },
    validatefields() {
      let validation = true;
      for (let i = 0; i < this.selectedLineItems.length; i += 1) {
        this.resetEmptyFields(i);
        const formObject = { ...this.selectedLineItems[i] };
        const formValidation = new ValidateEmptyFields(formObject);
        formValidation.emptyFields.forEach((key) => {
          this.emptyFields[i][key] = true;
        });
        const formValidationValue = this.validateValueLimits(this.limitValueEntered[i], i);
        if (!formValidation.validation || !formValidationValue) {
          validation = false;
        }
      }
      if (this.noContainerSelected) {
        this.emptyFields[0].container_name = true;
        validation = false;
      }
      return validation;
    },
    resetEmptyFields(index) {
      Object.keys(this.emptyFields[index]).forEach((key) => {
        this.emptyFields[index][key] = false;
      });
    },
    validateValueLimits(form, index) {
      let validation = true;
      const formKeys = Object.keys(form);
      for (let i = 0; i < formKeys.length; i += 1) {
        if (form[formKeys[i]] === true) {
          this.emptyFields[index][formKeys[i]] = true;
          validation = false;
        }
      }
      return validation;
    },
    addLineItem() {
      const newLine = {
        hu_count: null,
        lenght: null,
        width: null,
        height: null,
        weight: null,
      };
      this.emptyFields.push(this.getNewField("emptyFields"));
      this.limitValueEntered.push(this.getNewField());
      this.selectedLineItems.push(newLine);
    },
    deleteLineItem(index) {
      this.selectedLineItems.splice(index, 1);
      this.emptyFields.splice(index, 1);
      this.limitValueEntered.splice(index, 1);
    },
    getNewField(restriction) {
      const newObject = {
        lenght: false,
        width: false,
        height: false,
        weight: false,
      };
      if (restriction === "emptyFields") {
        newObject.hu_count = false;
      }
      return newObject;
    },
  },
};
</script>

<style lang="scss">
.lineItem-inputs {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
.form-label {
  font-weight: bold;
  text-align: start;
  color: $color-title;
  margin-bottom: 5px;
  @media (max-width: 900px) {
    font-size: 14px;
  }
  i {
    color: $color-error;
    margin-left: 35px;
  }
}

.form-input {
  margin-top: 10px;
}

.delete-btn {
  cursor: pointer;
  border: none;
  background-color: inherit;
  margin-top: 18px;
  i {
    font-size: 24px;
    color: $background-primary;
  }
}

.add-btn {
  cursor: pointer;
  border: none;
  color: $color-white;
  background-color: $background-primary;
  font-size: 16px;
  padding: 6px 25px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  i {
    font-size: 20px;
    margin-left: 5px;
  }
}

.missing-field {
  background-color: $color-missing-field;
}
</style>
