<template>
  <div class="calculate-linear-feet">
    <Header />
    <div class="calculate-linear-feet__title">
      <h2>Element registration</h2>
    </div>
    <div class="calculate-linear-feet__content">
      <div class="registration-element">
        <ContainerSelect v-model="containerSelected" @updateStackable="updateStackable" />
        <LineItems
          ref="lineItems"
          v-model="selectedLineItems"
          :containerSelected="containerSelected"
        />
      </div>
      <div class="linear-feet-section">
        <ContainerDetail :containerSelected="containerSelected" />
        <hr />
        <LinearFeetCalculation v-if="linearFeetCalc" :linearFeetCalc="linearFeetCalc" />
        <el-button v-loading="loading" class="calculate-btn" @click="calculateLinearfeet">
          Calculate
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import ContainerSelect from "./components/ContainerSelect.vue";
import ContainerDetail from "./components/ContainerDetail.vue";
import LineItems from "./components/LineItems.vue";
import LinearFeetCalculation from "./components/LinearFeetCalculation.vue";

export default {
  name: "CalculateLinearFeet",
  components: {
    Header,
    LinearFeetCalculation,
    ContainerSelect,
    ContainerDetail,
    LineItems,
  },
  data() {
    return {
      containerSelected: {},
      stackable: false,
      selectedLineItems: [],
      linearFeetCalc: null,
      loading: false,
    };
  },
  methods: {
    updateStackable(value) {
      this.stackable = value;
    },
    async calculateLinearfeet() {
      const formValidation = this.$refs.lineItems.validatefields();
      if (formValidation) {
        this.loading = true;
        const payload = {
          line_items: this.selectedLineItems,
          container_name: this.containerSelected.ref,
          stackable: this.stackable,
        };
        this.linearFeetCalc = await this.$store.dispatch("linearFeet/calculate", payload);
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.calculate-linear-feet {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  &__title {
    padding-bottom: 50px;
    padding-top: 10px;
    color: $color-title;
  }
  &__content {
    display: grid;
    grid-template-columns: (75% 20%);
    justify-content: center;
    justify-items: center;
  }
}
.registration-element {
  width: 95%;
}

hr {
  margin: 5px 8px;
  color: $color-text;
}

.calculate-btn {
  cursor: pointer;
  background-color: $color-text;
  color: $color-white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  height: 35px;
  margin-top: 10px;
  &:hover {
    background-color: darken($color-text, 3%);
  }
}

@media (max-width: 900px) {
  .calculate-linear-feet {
    &__content {
      grid-template-columns: 1fr;
    }
  }
}
::v-deep {
  .el-input__inner {
    &:focus {
      border-color: $background-primary !important;
    }
  }
  .el-checkbox__input {
    line-height: 2;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: $background-primary;
    border-color: $background-primary;
    width: 16px;
    height: 16px;
  }
  .el-checkbox__inner::after {
    left: 5px;
  }
  .missing-field .el-input__inner {
    background-color: $color-missing-field;
  }
}
</style>
