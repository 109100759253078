<template>
  <div class="linear-feet__detail">
    <div class="linear-feet__detail-img" v-if="Object.keys(containerSelected).length != 0">
      <img height="120" width="120" :src="containerSelected.img" alt="" />
    </div>
    <div class="linear-feet__detail-truck" v-if="Object.keys(containerSelected).length === 0">
      <i class="el-icon-truck"></i>
      <span>Choose one</span>
    </div>
    <div class="linear-feet__detail-info">
      <p>Container Name:{{ containerSelected.container_name }}</p>
      <p>Length:{{ containerSelected.lenght }} (In)</p>
      <p>Width:{{ containerSelected.width }} (In)</p>
      <p>Height:{{ containerSelected.height }} (In)</p>
      <p>Restriction weight per piece: {{ setWeight }}</p>
      <p>Volume(Cft): {{ setVolume }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContainerDetail",
  props: {
    containerSelected: Object,
  },
  computed: {
    setVolume() {
      return this.containerSelected.volume === Infinity
        ? "No Restrictions"
        : this.containerSelected.volume;
    },
    setWeight() {
      return this.containerSelected.weight === Infinity
        ? "No Restrictions"
        : `${this.containerSelected.weight} (Lb)`;
    },
  },
};
</script>

<style lang="scss">
.linear-feet {
  &__detail {
    display: flex;
    flex-direction: column;
  }
  &__detail-truck {
    display: flex;
    flex-direction: column;
    color: $color-text;
    i {
      font-size: 50px;
    }
    span {
      font-size: 12px;
      margin: 5px 0 20px 0;
    }
  }
  &__detail-info {
    margin-left: 10px;
    text-align: left;
    color: $color-text;
    font-size: 12px;
    p {
      margin-bottom: 5px;
      font-weight: bold;
    }
  }
}
</style>
