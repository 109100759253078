<template>
  <div class="header">
    <div class="logo-container">
      <img class="logo-container__logo" src="../assets/images/GLT-logo.png" alt="Megatron" />
    </div>
    <span>Megatron</span>
  </div>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style lang="scss">
.header {
  background-color: #edeff4;
  clip-path: polygon(100% 0, 100% 58%, 30% 58%, 25% 97%, 0 97%, 0 0);
  height: 150px;
  width: 100%;
  display: flex;
  @media (max-width: 1060px) {
    clip-path: none;
  }
  span {
    font-family: "Alfa Slab One", cursive;
    align-self: center;
    margin-left: 20px;
    font-size: 30px;
    color: $color-title;
  }
}

.logo-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  width: 140px;
  &__logo {
    width: 100%;
  }
}
</style>
