<template>
  <div class="lineItem-select">
    <div class="lineItem-select__select">
      <label class="select-label" for="containerName">Container name</label>
      <el-select
        :class="
          emptyFields[0]['container_name'] === true ? 'missing-field' : ''
        "
        @change="showDetailContainer(containerName)"
        v-model="containerName"
        placeholder="Select"
        size="mini"
      >
        <el-option
          v-for="item in options"
          :key="item.ref"
          :label="item.container_name.toUpperCase()"
          :value="item.ref"
        >
        </el-option>
      </el-select>
    </div>
    <div class="lineItem-select__checkbox">
      <label class="checkbox-label" for="stackable">Stackable</label>
      <el-checkbox v-model="stackable"></el-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import containers from "../../../utils/containers";

export default {
  name: "ContainerSelect",
  data() {
    return {
      containerName: "",
      stackable: false,
      options: [],
    };
  },
  computed: {
    ...mapGetters({
      emptyFields: "linearFeet/getEmptyFieldsLineItems",
    }),
  },
  watch: {
    stackable() {
      this.$emit("updateStackable", this.stackable);
    },
  },
  created() {
    this.options = containers;
  },
  methods: {
    showDetailContainer(name) {
      const containerSelected = containers.find((el) => el.ref === name);
      this.$emit("input", containerSelected);
    },
  },
};
</script>

<style lang="scss">
.select-label,
.checkbox-label {
  font-weight: bold;
  text-align: start;
  color: $color-title;
  margin-bottom: 10px;
  @media (max-width: 900px) {
    font-size: 14px;
  }
}

.form-input {
  margin-top: 10px;
}

.lineItem-select {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  &__select,
  &__checkbox {
    display: flex;
    flex-direction: column;
  }
  &__checkbox {
    margin-left: 10px;
  }
  &__calculate {
    display: flex;
    align-items: center;
  }
}
</style>
